@import "../variables";
.title {
    font-weight: bold;
    padding: 0 0 22px 0;
    margin: 0 5px 38px;
    border-bottom: 1px solid $border;
    color: $netlink-purple;

    &--login {
        font-weight: normal;
        padding: 0;
        border: none;
        margin-top:0;
        margin-bottom: 33px;
        font-size: 22px;
        color: $black;
    }
}

.title-dark {
    font-weight: bold;
    padding: 0 0 22px 0;
    margin: 0 5px 38px;
    border-bottom: 1px solid $border;
    color: $netlink-gray;

    &--login {
        font-weight: normal;
        padding: 0;
        border: none;
        margin-top:0;
        margin-bottom: 33px;
        font-size: 22px;
        color: $black;
    }
}
