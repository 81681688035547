.accessible_text_to_speech {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
}



.accessible_exit_button {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  .close {
    box-shadow: none;
    background-color: transparent;
    border: none;
    color: $white;
    font-size: 25px;
    line-height: 22px;
    cursor: pointer;
    outline: none;
}
.sr-only {display: none}
}